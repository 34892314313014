var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import translations from '../../utils/translations';
import { setComponentsManifest } from '../manifest';
import { buttonStructure } from '../structures/button';
import { getControllerStructure } from '../structures/controller';
import { Experiment } from '../../enums/Experiment';
import { getPremiumStatus } from '../../api';
import { APP_ID } from '../../constants';
export var getCurrentPage = function (editorSDK) { return editorSDK.pages.getCurrent(); };
export var getAppManifest = function (_editorSDK) { return __awaiter(void 0, void 0, Promise, function () {
    var showUpgradeButton, instance, isPremium, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                showUpgradeButton = false;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                return [4 /*yield*/, _editorSDK.info.getAppInstance(APP_ID)];
            case 2:
                instance = _a.sent();
                return [4 /*yield*/, getPremiumStatus(instance)];
            case 3:
                isPremium = _a.sent();
                if (!!isPremium) return [3 /*break*/, 5];
                return [4 /*yield*/, experimentsService.ready()];
            case 4:
                _a.sent();
                showUpgradeButton = experimentsService.isExperimentEnabled(Experiment.PayButtonCrownButton);
                _a.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                e_1 = _a.sent();
                // tslint:disable-next-line:no-console
                console.error(e_1);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/, setComponentsManifest({
                    showUpgradeButton: showUpgradeButton,
                    translations: {
                        mainAction1Label: translations.t('buttonGFPP.mainAction1'),
                        mainAction2Label: translations.t('buttonGFPP.mainAction2'),
                    },
                })];
        }
    });
}); };
export function getChildren(editorSDK, componentRef, appDefinitionId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editorSDK.components.getChildren(appDefinitionId, { componentRef: componentRef })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function addController(editorSDK, appDefinitionId, pageRef) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, editorSDK.components.add(appDefinitionId, {
                    pageRef: pageRef,
                    role: 'PayButton',
                    componentDefinition: getControllerStructure(appDefinitionId, buttonStructure),
                })];
        });
    });
}
export function getButtonText(editorSDK, appDefinitionId, componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        var button;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getChildren(editorSDK, componentRef, appDefinitionId)];
                case 1:
                    button = (_a.sent())[0];
                    return [4 /*yield*/, editorSDK.document.components.data.get(appDefinitionId, {
                            componentRef: button,
                        })];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function updateButtonLabel(editorSDK, appDefinitionId, componentRef, label, handleError) {
    return __awaiter(this, void 0, void 0, function () {
        var button, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, getChildren(editorSDK, componentRef, appDefinitionId)];
                case 1:
                    button = (_a.sent())[0];
                    return [4 /*yield*/, editorSDK.document.components.data.update(appDefinitionId, {
                            componentRef: button,
                            data: { label: label },
                        })];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    e_2 = _a.sent();
                    handleError(e_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
