export var defaultErrorHandler = function (error, errorMessage) {
    // tslint:disable-next-line:no-console
    console.error(errorMessage || error);
    throw error;
};
export var createHandleError = function (sentryErrorReporter) {
    return function (error, consoleErrorMessage) {
        sentryErrorReporter(error);
        defaultErrorHandler(error, consoleErrorMessage);
    };
};
